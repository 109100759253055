import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 获取月台管理列表、分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11000
 */
export function getList(data, params) {
  return wmsPlusHttp.post('/warehouse_foundation/dock/page', data, {
    params,
  });
}

/**
 * @description: 新增月台
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/10992
 */
export function insert(data) {
  return wmsPlusHttp.post('/warehouse_foundation/dock/insert', data);
}

/**
 * @description: 修改月台
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/10994
 */
export function update(data) {
  return wmsPlusHttp.post('/warehouse_foundation/dock/update', data);
}

/**
 * @description: 月台详情
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/10996
 */
export function detail(id) {
  return wmsPlusHttp.get(`/warehouse_foundation/dock/get?id=${id}`);
}

/**
 * @description: 禁用、启用
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/10996
 */
export function forbidden(params) {
  return wmsPlusHttp.post('/warehouse_foundation/dock/enable', null, { params });
}
