import { FormType } from '@/constant/form';

const STATUSTYPE = [{
  label: '闲置',
  value: 'IDLE',
}, {
  label: '锁定中（分配）',
  value: 'LOCKED',
}, {
  label: '使用中',
  value: 'USED',
},
];

const USEDTYPE = [{
  label: '启用',
  value: 'ENABLED',
}, {
  label: '禁用',
  value: 'DISABLED',
},
];

function filterLabel(val, arr) {
  const filterArr = arr.find((item) => item.value === val);
  return filterArr ? filterArr.label : '';
}

const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '月台号',
  prop: 'dockCode',
  minWidth: 240,
}, {
  label: '月台区',
  prop: 'zoneCode',
  minWidth: 180,
}, {
  label: '月台状态',
  prop: 'status',
  minWidth: 180,
  formatter: (row) => filterLabel(row.status, USEDTYPE),
}, {
  label: '使用状态',
  prop: 'usedStatus',
  minWidth: 180,
  formatter: (row) => filterLabel(row.usedStatus, STATUSTYPE),
}, {
  label: '月台用途',
  prop: 'zoneUsed',
  minWidth: 180,
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 180,
},
];

const DICTIONARIES = {
  ADD: 'add',
  EDIT: 'edit',
  VIEW: 'view',
  RECEIVING: '01',
  DELIVER: '02',
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
};

export function serchFields(zone) {
  return [
    {
      label: '月台号',
      prop: 'dockCode',
      component: FormType.INPUT,
    }, {
      label: '月台区',
      prop: 'zoneCode',
      component: FormType.SELECT,
      options: zone,
    }, {
      label: '使用状态',
      prop: 'usedStatus',
      component: FormType.SELECT,
      options: STATUSTYPE,
    }, {
      label: '月台状态',
      prop: 'status',
      component: FormType.SELECT,
      options: USEDTYPE,
    },
  ];
}

export const STATUS = 'ENABLED';

export {
  TABLECOLUMN,
  DICTIONARIES,
  STATUSTYPE,
};
