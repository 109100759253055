<template>
  <div>
    <nh-dialog
      v-model="visible"
      :title="info.title"
      custom-class="custom-dialog_50"
      :before-close="handleClose"
      :track="{
        name_zh: `基础数据/仓库相关/月台管理/弹窗-${
          info.status !== DICTIONARIES.VIEW ?
            (info.status === DICTIONARIES.EDIT ? '编辑' : '新增') : '查看'
        }`,
        trackName: $route.path
      }"
    >
      <el-form
        ref="editForm"
        :model="editForm"
        :rules="rules"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="月台号" prop="dockCode">
              <el-input
                v-if="info.status !== DICTIONARIES.VIEW"
                v-model="editForm.dockCode"
                :disabled="info.status === DICTIONARIES.EDIT"
              />
              <p v-else>
                {{ editForm.dockCode }}
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="月台区:" prop="zoneCode">
              <el-select
                v-if="info.status !== DICTIONARIES.VIEW"
                v-model="editForm.zoneCode"
                placeholder="--请选择--"
              >
                <el-option
                  v-for="(item, index) in zone"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <p v-else>
                {{ filterZone(editForm.zoneCode, zone) }}
              </p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="月台用途" prop="balconyUse">
          <el-checkbox-group
            v-model="editForm.balconyUse"
            :disabled="info.status === DICTIONARIES.VIEW"
          >
            <el-checkbox label="01">
              收货
            </el-checkbox>
            <el-checkbox label="02">
              发货
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="描述:" prop="description">
          <el-input
            v-if="info.status !== DICTIONARIES.VIEW"
            v-model="editForm.description"
            type="textarea"
          />
          <p v-else>
            {{ editForm.description }}
          </p>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <nh-button @click="handleClose">关 闭</nh-button>
          <nh-button
            v-if="info.status !== DICTIONARIES.VIEW"
            :loading="loading.handleSubmit"
            @click="handleReset('editForm')"
          >重 置</nh-button>
          <nh-button
            v-if="info.status !== DICTIONARIES.VIEW"
            type="primary"
            :loading="loading.handleSubmit"
            @click="handleSubmit('editForm')"
          >确 定</nh-button>
        </span>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import { insert, update, detail } from '../api';
import { DICTIONARIES } from '../fileds';
import Mixin from '../mixin';

export default {
  name: 'LoadingEdit',
  mixins: [Mixin],
  emits: ['submitSuccess'],
  data() {
    return {
      DICTIONARIES,
      editForm: {
        dockCode: '',
        balconyUse: [],
        zoneCode: '',
        description: '',
      },
      editFormCope: {},
      rules: {
        dockCode: [
          { required: true, message: '请输入月台号', trigger: 'blur' },
        ],
        zoneCode: [
          { required: true, message: '请选择月台区', trigger: 'change' },
        ],
        balconyUse: [
          { required: true, message: '请选择月台用途', trigger: 'change' },
        ],
      },
      visible: false,
      info: {},
      loading: {
        handleSubmit: false,
      },
    };
  },
  computed: {
    idEdit() {
      return this.info.status === DICTIONARIES.EDIT;
    },
  },
  methods: {
    init(info) {
      this.visible = true;
      this.info = {
        ...info,
      };
      if (info.status !== DICTIONARIES.ADD) {
        this.getDetail(info.id);
      }
    },
    handleClose() {
      this.editForm = {
        dockCode: '',
        balconyUse: [],
        zoneCode: '',
        description: '',
      };
      this.$refs.editForm.resetFields();
      this.visible = false;
    },
    async getDetail(id) {
      const result = await detail(id);
      const balconyUse = [];
      if (result.storeArea) {
        balconyUse.push('01');
      }
      if (result.deliverArea) {
        balconyUse.push('02');
      }
      this.editForm = {
        balconyUse,
      };
      Object.keys(result).forEach((item) => {
        if (item !== 'storeArea' || item !== 'deliverArea') {
          this.editForm[item] = result[item];
        }
      });
      this.editForm = { ...this.editForm };
      this.editFormCope = { ...this.editForm };
    },
    handleSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const data = {
            ...this.editForm,
            storeArea: this.editForm.balconyUse.some((item) => item === DICTIONARIES.RECEIVING),
            deliverArea: this.editForm.balconyUse.some((item) => item === DICTIONARIES.DELIVER),
            status: 'ENABLE',
            usedStatus: 'IDLE',
          };
          if (this.info.status === DICTIONARIES.EDIT) {
            await update(data);
          } else {
            await insert(data);
          }
          this.$emit('submitSuccess', this.idEdit ? '编辑' : '新增');
          this.handleClose();
        }
      });
    },
    handleReset(formName) {
      if (this.idEdit) {
        this.editForm = {
          ...this.editForm,
          ...this.editFormCope,
        };
      } else {
        this.$refs[formName].resetFields();
      }
    },
    filterZone(val, zone) {
      const zoneResult = zone && zone.find((item) => item.value === val);
      return zoneResult && zoneResult.label;
    },
  },
};
</script>
