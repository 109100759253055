<template>
  <pl-block>
    <PlForm
      v-model="searchForm"
      mode="search"
      :fields="serchFields(zone)"
      @submit="handleSearch"
      @reset="handleSearch"
    />
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getReservoirList"
      :data="reservoirList"
      :columns="TABLECOLUMN"
    >
      <template #tableHeaderRight>
        <nh-button
          :track="{
            trackName: '基础数据/仓库相关/月台管理/[新建]'
          }"
          @click="handleInsert"
        >
          新建
        </nh-button>
      </template>
      <template #zoneCode="{row}">
        <span>{{ filterZone(row.zoneCode, zone) }}</span>
      </template>
      <template #zoneUsed="{row}">
        <span>{{ filterUsed(row) }}</span>
      </template>
      <template #action="{row}">
        <nh-button type="text" @click="handleReservoirView(row)">
          查看
        </nh-button>
        <nh-button type="text" @click="handleReservoirEdit(row)">
          编辑
        </nh-button>
        <nh-button
          type="text"
          @click="handleReservoirStart(row)"
        >
          {{ row.status === STATUS ? '禁用' : '启用' }}
        </nh-button>
      </template>
    </PlTable>
  </pl-block>

  <Edit ref="edit" @submitSuccess="handleSubmit" />
</template>
<script>
import loadingMixin from '@thales/loading';
import Edit from './components/edit.vue';
import {
  TABLECOLUMN, DICTIONARIES, STATUS, serchFields, STATUSTYPE,
} from './fileds';
import { getList, forbidden } from './api';
import Mixin from './mixin';

export default {
  name: 'LoadingPort',
  components: {
    Edit,
  },
  mixins: [loadingMixin, Mixin],
  data() {
    return {
      TABLECOLUMN,
      DICTIONARIES,
      serchFields,
      STATUSTYPE,
      STATUS,
      that: this,
      reservoirList: [],
      searchForm: {
        dockCode: undefined,
        zoneCode: undefined,
        status: undefined,
        usedStatus: undefined,
      },
      loading: {
        getReservoirList: false,
      },
    };
  },
  watch: {
    pagination() {
      this.getReservoirList();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getReservoirList();
    },
    async getReservoirList() {
      const data = {
        ...this.searchForm,
      };
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const result = await getList(data, pagination);
      this.pagination.total = result.total;
      this.reservoirList = result.records;
    },
    handleSearch() {
      this.pagination.page = 1;
      this.getReservoirList();
    },
    handleSubmit(text) {
      this.$message({
        type: 'success',
        message: `${text}成功!`,
      });
      this.getReservoirList();
    },
    handleInsert() {
      const info = {
        title: '新建',
        status: DICTIONARIES.ADD,
      };
      this.$refs.edit.init(info);
    },
    handleReservoirEdit(rows) {
      const info = {
        title: '编辑',
        status: DICTIONARIES.EDIT,
        id: rows.id,
      };
      this.$refs.edit.init(info);
    },
    handleReservoirView(rows) {
      const info = {
        title: '查看详情',
        status: DICTIONARIES.VIEW,
        id: rows.id,
      };
      this.$refs.edit.init(info);
    },
    async handleReservoirStart(row) {
      try {
        await this.$confirm(
          `是否确认${row.status === this.STATUS ? '禁用' : '启用'}${row.dockCode}月台?`,
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          },
        );
        await forbidden(
          {
            statusEnum: row.status === this.STATUS ? DICTIONARIES.DISABLED : DICTIONARIES.ENABLED,
            id: row.id,
          },
          row.id,
        );
        this.$message({
          type: 'success',
          message: `${row.status === this.STATUS ? '禁用' : '启用'}成功!`,
        });
        this.getReservoirList(1);
      } catch {
        this.$message({
          type: 'success',
          message: '你已经取消!',
        });
      }
    },
    filterZone(val, zone) {
      const zoneItem = zone && zone.find((item) => item.value === val);
      return zoneItem && zoneItem.label;
    },
    filterUsed(val) {
      const used = [];
      if (val.deliverArea) {
        used.push('发货');
      }
      if (val.storeArea) {
        used.push('收货');
      }
      return used.join();
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
